import React, { Component } from 'react';

import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Privacy " keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Accessibility Statement</h1>
        </PageHeader>
        <div className="page childPadding">
          <p>This is an accessibility statement from Polypane B.V..</p>

          <h2>Measures to support accessibility</h2>

          <p>Polypane B.V. takes the following measures to ensure accessibility of Polypane:</p>
          <ul>
            <li>Include accessibility as part of our mission statement.</li>
            <li>Include accessibility throughout our internal policies.</li>
            <li>Integrate accessibility into our procurement practices.</li>
            <li>Provide continual accessibility training for our staff.</li>
            <li>Assign clear accessibility goals and responsibilities.</li>
            <li>Employ formal accessibility quality assurance methods.</li>
          </ul>

          <h2>Conformance status</h2>

          <p>
            The Web Content Accessibility Guidelines (WCAG) defines requirements for designers and developers to improve
            accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and
            Level AAA. Polypane is partially conformant with WCAG 2.1 level AA. Partially conformant means that some
            parts of the content do not fully conform to the accessibility standard.
          </p>

          <h4>Feedback</h4>

          <p>
            We welcome your feedback on the accessibility of Polypane. Please let us know if you encounter accessibility
            barriers on Polypane:
          </p>
          <ul>
            <li>
              E-mail: <a href="mailto:support@polypane.app">support@polypane.app</a>
            </li>
            <li>
              Via the <a href="https://polypane.app/support/">support page</a>
            </li>
          </ul>

          <p>We try to respond to feedback within 2 business days.</p>

          <h2>Technical specifications</h2>

          <p>
            Accessibility of Polypane relies on the following technologies to work with the particular combination of
            web browser and any assistive technologies or plugins installed on your computer:
          </p>

          <ul>
            <li>HTML</li>
            <li>WAI-ARIA</li>
            <li>CSS</li>
            <li>JavaScript</li>
          </ul>

          <p>These technologies are relied upon for conformance with the accessibility standards used.</p>

          <h2>Limitations and alternatives</h2>

          <p>
            Despite our best efforts to ensure accessibility of Polypane, there may be some limitations. Below is a
            description of known limitations, and potential solutions. Please contact us if you observe an issue not
            listed below.
          </p>

          <h3>Known limitations for the Polypane website and application:</h3>
          <ul>
            <li>
              <strong>Video elements</strong>: Not all videos elements have captions or text alternatives. We make sure
              that all information is available through other methods.
            </li>
            <li>
              <strong>Focus management</strong>: Not all parts of the Polypane application can easily be reached or
              navigated by assistive technologies. While all elements are labeled and keyboard accessible, not every
              change is alerted.
            </li>
          </ul>

          <h2>Assessment approach</h2>

          <p>Polypane B.V. assessed the accessibility of Polypane by the following approaches:</p>

          <ul>
            <li>Self-evaluation</li>
            <li>Automated testing</li>
          </ul>

          <h2>Date</h2>

          <p>This statement was created on 11 June 2021 using the W3C Accessibility Statement Generator Tool.</p>
        </div>
      </Layout>
    );
  }
}

export default Page;
